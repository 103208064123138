//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Painting",
  data() {
    return {
      conflux: null,
      track: "",

      eraser: false,
      show2: false,
      show: false,
      title: "",
      price: "",
      pic1: "",
      pic2: "",
      picname: "",
      hash: "",
      desc: "",
      signanme: "",

      fg: 0,
      screenWidth: 0,

      zm: 10,
      zs: 9,
      signaturePad: null,
      canvas: null,
      color: "#000000",
      thickness: 0.5,

      signaturePad2: null,
      canvascolor: null,
      color2: "background:rgba(249, 0, 0, .8)",
      thickness2: 2.5,

      cfxtype: "0",
    };
  },
  mounted() {
    window.setMybalance = function (val) {};
    window.setMyearned = function (val) {};
    window.setMyprofit = function (val) {};
    window.setMyart = function (val) {};
    window.setMycoinname = function (val) {};

    window.eraser = true;
    this.screenWidth = 600; //document.body.clientWidth; // 屏幕宽
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(function () {
        if (document.body.clientWidth < 768) {
          this.$router.replace({ path: "/painting2" });
        }
        const wrapper = document.getElementById("signature-pad");
        this.canvas = wrapper.querySelector("canvas");
        this.signaturePad = new this.$signature(this.canvas, {
          throttle: 5,
          velocityFilterWeight: 0,
          dotSize: this.thickness,
          minWidth: this.thickness,
          maxWidth: this.thickness,
          backgroundColor: "rgba(255, 255, 255, .1)",
        });

        const wrapper2 = document.getElementById("signature-pad2");
        this.canvascolor = wrapper2.querySelector("canvas");
        this.signaturePad2 = new this.$signature(this.canvascolor, {
          throttle: 5,
          velocityFilterWeight: 0,
          dotSize: this.thickness2,
          minWidth: this.thickness2,
          maxWidth: this.thickness2,
          backgroundColor: "rgba(255, 255, 255, .1)",
        });

        window.onresize = this.resizeCanvas();
        this.resizeCanvas();

        // 合约
        if (this.$store.state.address) {
          this.islogin = true;
          this.name =
            this.$store.state.address.substring(0, 6) +
            "..." +
            this.$store.state.address.substring(36, 42);
        }

        this.conflux = new this.$conflux({
          url: window.RPC_URL,
          networkId: 1029,
          logger: console,
        });
        this.conflux.provider = window.conflux;

        if (this.conflux === "undefined") {
          if (
            confirm(
              "检测到您的浏览器中并未安装conflux钱包插件，点击确定前往下载。\n\n 注：如果没有跳转看下是不是被浏览器拦截了"
            )
          ) {
            window.open("https://github.com/Conflux-Chain/conflux-portal/releases");
          }
        } else {
          const contract = this.conflux.Contract({
            abi: CONTRACT_ABI,
            address: CONTRACT_ADDRESS,
            //address: "0x8067244e0b629610eb24cc9da4fe8b023fc02fd9",
          });
          //console.log(contract);
        }
      });
    },
    resizeCanvas() {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d").scale(ratio, ratio);
      //this.canvas.getContext("2d").globalAlpha=0.2;
      //this.canvas.getContext("2d").fillStyle = 'rgba(255, 255, 255, 0)';
      this.signaturePad.clear();

      this.canvascolor.width = this.canvascolor.offsetWidth * ratio;
      this.canvascolor.height = this.canvascolor.offsetHeight * ratio;
      this.canvascolor.getContext("2d").scale(ratio, ratio);
      //this.canvas.getContext("2d").globalAlpha=0.2;
      //this.canvas.getContext("2d").fillStyle = 'rgba(255, 255, 255, 0)';
      this.signaturePad2.clear();
    },
    // 橡皮擦
    ca() {
      window.eraser = false;
      this.eraser = true;
    },
    ca2() {
      window.eraser = true;
      this.eraser = false;
    },
    // 撤销
    undo() {
      window.eraser = true;
      this.eraser = false;

      const data = this.signaturePad.toData();
      //console.log(this.signaturePad._thickness);
      //console.log(data);
      if (data) {
        data.pop(); // remove the last dot or line
        this.signaturePad.fromData(data);
      }
    },
    // 清空
    clear() {
      this.$dialog
        .confirm({
          title: "清空画板？",
        })
        .then(() => {
          this.signaturePad.clear();
        })
        .catch(() => {
          // on cancel
        });
    },
    // 撤销颜色
    undo2() {
      window.eraser = true;
      this.eraser = false;

      const data = this.signaturePad2.toData();

      if (data) {
        data.pop(); // remove the last dot or line
        this.signaturePad2.fromData(data);
      }
    },
    // 清空颜色
    clear2() {
      this.$dialog
        .confirm({
          title: "清空画板？",
        })
        .then(() => {
          this.signaturePad2.clear();
        })
        .catch(() => {
          // on cancel
        });
    },
    // 铅笔颜色
    changeColor() {
      this.signaturePad.penColor = this.color;
    },
    // 画笔颜色
    changeColor2() {
      window.eraser = true;
      this.eraser = false;
      for (let index = 1; index <= 16; index++) {
        document.getElementById("color" + index).className = "kuai";
      }
      document.getElementById("color16").className = "kuai";
      this.signaturePad2.penColor = this.color2;
    },
    // 切换画布 上色
    changeS() {
      this.zm = 9;
      this.z = 10;
      //this.signaturePad2.penColor = this.color2;
      let timeuid = "";
      if (!localStorage.getItem("_timeuid")) {
        timeuid = new Date().getTime();
        localStorage.setItem("_timeuid", timeuid);
      } else {
        timeuid = localStorage.getItem("_timeuid");
      }

      // 获取MD5验证码 (防抓包加密)
      this.$axios
        .put(
          this.$store.state.api + "/track",
          this.$qs.stringify({
            id: timeuid,
          })
        )
        .then((response) => {
          this.track = response.data;
        });
    },
    changeM() {
      this.zm = 10;
      this.z = 9;
    },
    // 画笔大小
    thin1(thickness) {
      this.thickness = thickness;
      this.signaturePad.dotSize = thickness;
      this.signaturePad.minWidth = thickness;
      this.signaturePad.maxWidth = thickness;
    },
    thin2(thickness) {
      this.thickness2 = thickness;
      this.signaturePad2.dotSize = thickness;
      this.signaturePad2.minWidth = thickness;
      this.signaturePad2.maxWidth = thickness;
    },

    kuai(color, i) {
      window.eraser = true;
      this.eraser = false;
      for (let index = 1; index <= 16; index++) {
        document.getElementById("color" + index).className = "kuai";
      }
      document.getElementById("color" + i).className = "kuai actc";
      this.signaturePad2.penColor = color;
    },

    //检测是否安装 Portal 插件 -------------------------------------------------------------------------
    checkPortal() {
      this.conflux == undefined ? window.conflux : conflux;
      if (typeof this.conflux == undefined) {
        return false;
      } else {
        return true;
      }
    },

    // 调起铸造
    async makeitpre() {
      if (this.signaturePad.isEmpty()) {
        alert("请先绘图");
        return;
      }
      if (this.signaturePad2.isEmpty()) {
        alert("请为图画上色");
        return;
      }

      if (!this.picname) {
        alert("点击智能生成 再进行铸造");
        return;
      }

      console.log(this.$store.state.address);
      if (this.checkPortal()) {
        let accounts = null;
        let _address = "";
        try {
          accounts = await window.conflux.enable();

          // 新版本手机
          if (window.isArrayFn(accounts)) {
            _address = accounts[0];
          } else {
            const accounts2 = await window.conflux.send({ method: "cfx_accounts" });
            _address = accounts2.result[0];
          }
        } catch (error) {}
        window._address = _address;

        window.accounts = accounts;
        this.$store.state.address = _address; // 获取区块链唯一标识
        localStorage.setItem("_address", _address);
        this.show = true;
      } else {
        layer.alert("未安装钱包插件！");
      }
    },

    // 铸造
    async makeit() {
      if (!this.title) {
        alert("请输入标题");
        return;
      }

      if (!this.price) {
        alert("请输入价格");
        return;
      }

      this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
        "_address"
      );
      let timeuid = localStorage.getItem("_timeuid");
      this.$axios.defaults.headers.common["T-TOKEN"] = timeuid;

      // 服务器 图片转到正式文件
      this.$axios
        .put(this.$store.state.api + "/filetransfer", {
          type: 1,
          img: this.picname,
        })
        .then(async (response) => {
          const data = response.data;
          console.log(data);
        });

      let accounts = null;
      let _address = "";
      try {
        accounts = await window.conflux.enable();
      } catch (error) {}
      // 新版本手机
      if (window.isArrayFn(accounts)) {
        _address = accounts[0];
      } else {
        const accounts2 = await window.conflux.send({ method: "cfx_accounts" });
        _address = accounts2.result[0];
      }
      window._address = _address;

      localStorage.setItem("_address", _address);

      document.getElementById("accname").innerHTML =
        '<div class="qb_btn">' +
        _address.substring(0, 6) +
        "..." +
        _address.substring(45, _address.lenght) +
        "</div>";

      // post 到 paintings表
      this.$axios
        .post(this.$store.state.api + "/paintings", {
          tokenid: 0,
          contract_address: ArtNft_V2_ADDRESS,
          tmp_uid: +timeuid, // 临时身份  picpath
          address: _address,
          hash: this.hash,
          img: this.picname,
          price: this.price,
          name: this.title,
          signature: this.signanme,
          introduction: this.desc,
          is_cast: 0,
        })
        .then(async (response) => {
          const data = response.data;

          if (data) {
            this.show2 = true;
            this.show = false;

            try {
              /*
              const _artNFT = await ArtNFT.mint(data.hash)
                .sendTransaction({
                  value: +this.$drip.fromCFX(+data.price),
                  from: _address,
                })
                .executed();
                */
              let _address = await window.getAddress();
              // 铸造合约
              const ArtNFT_V2 = await this.conflux.Contract({
                abi: ArtNft_V2_ABI,
                address: ArtNft_V2_ADDRESS,
              });

              // 是否授权
              const ACFX = this.conflux.Contract({
                abi: ACFX_ABI,
                address: ACFX_ADDRESS,
              });
              const is = await ACFX.isOperatorFor(ArtNft_V2_ADDRESS, _address);
              if (!is) {
                await ACFX.authorizeOperator(ArtNft_V2_ADDRESS)
                  .sendTransaction({
                    from: _address,
                  })
                  .executed();
              }

              // 铸造
              let acfx = 0;
              let cfx = 0;
              if (this.cfxtype === "0") {
                cfx = +this.$drip.fromCFX(+data.price);
              } else {
                acfx = +this.$drip.fromCFX(+data.price);
              }
              const _artNFT = await ArtNFT_V2.mint(data.hash, +acfx)
                .sendTransaction({
                  value: +cfx,
                  from: _address,
                })
                .executed();

              //const tokens = await ArtNFT.tokensOf(_address);
              const tokens = await ArtNFT_V2.tokensOf(_address);
              const tokenid = tokens.pop(); // 新铸造的NFT

              // 放到 数字资产数据库
              this.$axios
                .put(this.$store.state.api + "/paintings/" + data.id, {
                  tokenid: tokenid,
                  is_cast: 1,
                })
                .then((response) => {
                  this.show2 = false;
                  this.$router.push({ path: "/my" });
                });
            } catch (error) {
              console.log(error);
              this.show2 = false;
              this.show = false;
            }
          }
        });
    },
    closes() {
      this.show = false;
    },

    // 调起智能图片
    savepng() {
      if (this.signaturePad.isEmpty()) {
        alert("请画线框");
        return;
      }
      if (this.signaturePad2.isEmpty()) {
        alert("请为图画上色");
        return;
      }

      if (this.signaturePad.isEmpty()) {
        //alert("Please provide a signature first.");
      } else {
        const dataURL = this.signaturePad.toDataURL();
        this.download(1, dataURL, "base.png");
      }
    },
    // 智能匹配ing
    download(i, dataURL, filename) {
      if (
        navigator.userAgent.indexOf("Safari") > -1 &&
        navigator.userAgent.indexOf("Chrome") === -1
      ) {
        window.open(dataURL);
      } else {
        //var blob = this.dataURLToBlob(dataURL);
        //var url = window.URL.createObjectURL(blob);

        // 智能计算
        /**/
        this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
          "_address"
        );
        let timeuid = localStorage.getItem("_timeuid");
        this.$axios.defaults.headers.common["T-TOKEN"] = timeuid;

        const t = new Date().getTime();
        this.$axios
          .post(
            this.$store.state.api + "/algorithm?t=" + t,
            this.$qs.stringify({
              i: i,
              type: this.fg,
              img: dataURL.slice(0, 275) + this.track + dataURL.slice(275) + this.track, // 智能生成的图片 地址
            })
          )
          .then((response) => {
            const data = response.data;
            const name = data.split(",")[0];
            //console.log(name);
            if (name === "processing") {
              if (!this.signaturePad2.isEmpty()) {
                const dataURL = this.signaturePad2.toDataURL();
                this.download(2, dataURL, "color.png");
              }
              return;
            }
            const hash = data.split(",")[1];

            this.picname = name;
            this.hash = hash;

            //const pic_url= this.$store.state.domain + "/temp_pic/" + timeuid + "/output/" + name;
            const pic_url =
              this.$store.getters.nft_url_t.replace("_pro", "") +
              timeuid +
              "/output/" +
              name;

            document.getElementById("znt1").style =
              "background-image: url('" + pic_url + "')";
            document.getElementById("znt2").style =
              "background-image: url('" + pic_url + "')";
            document.getElementById("znt3").src = pic_url;
          });
      }
    },
    dataURLToBlob(dataURL) {
      var parts = dataURL.split(";base64,");
      var contentType = parts[0].split(":")[1];
      var raw = window.atob(parts[1]);
      var rawLength = raw.length;
      var uInt8Array = new Uint8Array(rawLength);

      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
  },
};
